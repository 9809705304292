import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next";
import { postRedsys } from '../actions/qrs'
import { fetchCustomer } from '../actions/qrs'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import StepBar from '../components/StepBar'

class PaymentView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      redsys: null,
      iva: 21,
      redsysSignature: null,
      redsysMerchant: null,
      redsysVersion: null,
      total: null,
      importeBase: null,
      importeIva: null,
      minutes: 5,
      seconds: 0,
      isTimeUp: false,
    }

    this.toggle = this.toggle.bind(this);
    this.getLockerType = this.getLockerType.bind(this);

  }

  componentDidMount() {
    const { 
      zoneSelected, 
      descriptionZone, 
      selectedAccessibility, 
      selectedLocker, 
      lockerPrice, 
      lockerAssigned,
      lockerPin, 
      lockerMatricula, 
      lockerCustomer, 
      lockerCustomerID,
      qr,
      qrType, 
      qrMode,
      mail,
      phoneNumber,
      newsletterAcceptance,
      confirmReservation

    } = this.props.location.state;

    const { t, i18n } = this.props

    //Idiomas Redsys - Castellano-001, Inglés-002, Catalán-003, Francés-004, Alemán-005, Holandés-006, Italiano-007, Sueco-008, Portugués-009, Valenciano-010, Polaco-011, Gallego-012 y Euskera-013.
    console.log("Language: " + i18n.language);

    let language = '001'

    switch (i18n.language) {
      case "es": language = '001'; break;
      case "en": language = '002'; break;
      case "it": language = '007'; break;
      case "gr": language = '005'; break;
      case "fr": language = '004'; break;
      case "pt": language = '009'; break;
    }

    this.startCountdown();

    let redsysPetition = {}

    let paymentId = Math.floor((Math.random() * 1000000) + 1).toString()

    let redsysURLOK = `https://lockers-qr.vacway.es/TicketView?zoneSelected=${zoneSelected}&descriptionZone=${descriptionZone}&selectedAccessibility=${selectedAccessibility}&selectedLocker=${selectedLocker}&lockerPrice=${lockerPrice}&lockerAssigned=${lockerAssigned}&lockerPin=${lockerPin}&lockerMatricula=${lockerMatricula}&lockerCustomer=${lockerCustomer}&qrToken=${qr}&qrType=${qrType}&qrMode=${qrMode}&mail=${mail}&phoneNumber=${phoneNumber}&newsletterAcceptance=${newsletterAcceptance}&confirmReservation=${confirmReservation}&language=${i18n.language}&paymentId=${paymentId}`
    let redsysURLKO = `https://lockers-qr.vacway.es/PaymentError?qr=${qr}&language=${i18n.language}`

    redsysPetition.price = lockerPrice
    redsysPetition.redsysURLOK = redsysURLOK
    redsysPetition.redsysURLKO = redsysURLKO
    redsysPetition.paymentId = paymentId

    redsysPetition.merchantCode = '351639505'
    redsysPetition.currency = '978'
    redsysPetition.terminal = '1'
    redsysPetition.merchantUrl = 'https://vacway.com'
    redsysPetition.secretKey = 'xhI+J4wrx0JKkeGqJ12j2+ErLNH9OpHM'
    redsysPetition.language = language

    this.props.fetchCustomer(lockerCustomerID)
      .then((customer) => {

        console.log("Customer: ", customer.response)
        console.log("Redsys: ", customer.response.Redsys)

        redsysPetition.merchantCode = customer.response.Redsys.MERCHANTCODE
        redsysPetition.currency = customer.response.Redsys.CURRENCY
        redsysPetition.terminal = customer.response.Redsys.TERMINAL
        redsysPetition.merchantUrl = customer.response.Redsys.MERCHANTURL
        redsysPetition.secretKey = customer.response.Redsys.SECRETKEY

        console.log("redsysPetition: ", redsysPetition);

        this.props.postRedsys(redsysPetition)
          .then((answer) => {
            let answerRedsys = answer.response

            let redsysSignature = answerRedsys.Ds_Signature
            let redsysMerchant = answerRedsys.Ds_MerchantParameters
            let redsysVersion = answerRedsys.Ds_SignatureVersion

            this.TotalComponent()

            this.setState({ redsys: answerRedsys, redsysSignature: redsysSignature, redsysMerchant: redsysMerchant, redsysVersion: redsysVersion });

          })
          .catch((error) => {
            console.error(error);
          });

      })
      .catch((error) => {
        console.error(error);
      });

  }

  componentWillUnmount() {
    const { qr, lockerAssigned } = this.props.location.state;

    clearInterval(this.countdownInterval);

    history.push(`/qrsale/${qr}?lockerAssigned=${lockerAssigned}`)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  goTo(number, e) {
    history.push(`/phoneView`)
  }

  handleRedirect = () => {
    const { qr, lockerAssigned } = this.props.location.state;
    history.push(`/qrsale/${qr}?lockerAssigned=${lockerAssigned}`)
  };

  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
      } else {
        if (this.state.minutes > 0) {
          this.setState((prevState) => ({
            minutes: prevState.minutes - 1,
            seconds: 59,
          }));
        } else {
          clearInterval(this.countdownInterval);
          // Here you can add any logic you want to perform after the countdown reaches 0
          this.setState({ isTimeUp: true });
        }
      }
    }, 1000);
  };

  TotalComponent() {
    const { iva } = this.state;
    const { lockerPrice } = this.props.location.state;

    let importeBase = (lockerPrice / (1 + (iva / 100))).toFixed(2)
    let importeIva = (lockerPrice - importeBase).toFixed(2)
    let total = lockerPrice

    this.setState({ importeBase: importeBase, importeIva: importeIva, total: total });

    return { total, importeBase, importeIva, iva };
  }

  getLockerType(type) {

    const { t, i18n } = this.props

    switch (type) {

      case "BNS":
        return t('BÁSICA')
      case "CNS":
        return t('BÁSICA CARGA')
      case "BWS":
        return t('BÁSICA XL')
      case "CWS":
        return t('BÁSICA CARGA XL')
      case "BNM":
        return t('GRANDE')
      case "CNM":
        return t('GRANDE CARGA')
      case "BWM":
        return t('GRANDE XL')
      case "CWM":
        return t('GRANDE CARGA XL')
      case "BNL":
        return t('GRANDE')
      case "CNL":
        return t('GRANDE CARGA')
      case "BWL":
        return t('GRANDE XL')
      case "CWL":
        return t('GRANDE CARGA XL')
      case "BNXL":
        return t('GRANDE')
      case "CNXL":
        return t('GRANDE CARGA')
      case "BWXL":
        return t('GRANDE XL')
      case "CWXL":
        return t('GRANDE CARGA XL')
      case "BN2XL":
        return t('GRANDE')
      case "CN2XL":
        return t('GRANDE CARGA')
      case "BW2XL":
        return t('GRANDE XL')
      case "CW2XL":
        return t('GRANDE CARGA XL')
      case "BN3XL":
        return t('SKI')
      case "CN3XL":
        return t('SKI CARGA')
      case "BW3XL":
        return t('SKI XL')
      case "CW3XL":
        return t('SKI CARGA XL')
      default:
        return t('BÁSICA')
    }
  }

  redsysForm() {

    const { redsys, redsysSignature, redsysMerchant, redsysVersion, minutes, seconds } = this.state;
    const { 
      zoneSelected, 
      descriptionZone, 
      selectedAccessibility, 
      selectedLocker, 
      lockerPrice, 
      lockerAssigned,
      lockerPin, 
      lockerMatricula, 
      lockerCustomer, 
      lockerCustomerID,
      qr,
      qrType, 
      qrMode,
      mail,
      phoneNumber,
      newsletterAcceptance,
      confirmReservation

    } = this.props.location.state;

    const { t, i18n } = this.props

    const paymentId = Math.floor((Math.random() * 1000000) + 1).toString();
    const productionUrl = "https://sis.redsys.es/sis/realizarPago"

    const iappaURL = `https://lockers-qr.vacway.es/TicketView?zoneSelected=${zoneSelected}&descriptionZone=${descriptionZone}&selectedAccessibility=${selectedAccessibility}&selectedLocker=${selectedLocker}&lockerPrice=${lockerPrice}&lockerAssigned=${lockerAssigned}&lockerPin=${lockerPin}&lockerMatricula=${lockerMatricula}&lockerCustomer=${lockerCustomer}&qrToken=${qr}&qrType=${qrType}&qrMode=${qrMode}&mail=${mail}&phoneNumber=${phoneNumber}&newsletterAcceptance=${newsletterAcceptance}&confirmReservation=${confirmReservation}&language=${i18n.language}&paymentId=${paymentId}`

    const handleButtonClick = () => {
      window.location.href = iappaURL;
    };

    if (!redsys || !redsysSignature || !redsysMerchant || !redsysVersion) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    } else {

      if (qrType === "pay") {
        return (
          <html>
            <body>
              <form action="https://sis.redsys.es/sis/realizarPago" method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="submit" value={t('PAGAR')} className="col lockerPayButton" />
              </form>
            </body>
          </html>
        );
      } else if (qrType === "free") {
        return (
          <html>
            <body>
              <form method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="button" value={t('RESERVAR')} className="col lockerPayButton" onClick={handleButtonClick} />
              </form>
            </body>
          </html>
        );
      } else if (qrType === "test") {
        return (
          <html>
            <body>
              <form action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="submit" value={t('PAGAR')} className="col lockerPayButton" />
              </form>
            </body>
          </html>
        );
      } else {
        return (
          <html>
            <body>
              <form action="https://sis.redsys.es/sis/realizarPago" method="POST" accept-charset="UTF-8" name="peticionRedsys">
                <input type="hidden" name="Ds_SignatureVersion" value={`${redsysVersion}`} />
                <input type="hidden" name="Ds_MerchantParameters" value={`${redsysMerchant}`} />
                <input type="hidden" name="Ds_Signature" value={`${redsysSignature}`} />
                <input type="submit" value={t('PAGAR')} className="col lockerPayButton" />
              </form>
            </body>
          </html>
        );
      }
    }
  }

  render() {
    const { t, i18n } = this.props
    const { redsys, iva, importeBase, importeIva, total, minutes, seconds, isTimeUp } = this.state;
    const { zoneSelected, selectedLocker, selectedAccessibility, lockerCustomer, qrMode } = this.props.location.state;

    if (!redsys) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    } else {
      return (
        <div>
          <AppHeader />
          <div className="lockerContainer">
            <br></br>
            <StepBar step={4} />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('CONFIRMA TU COMPRA')}</span>
            </h3>
            <hr className="bg-vw-light" />
            <div className="countdownTPV">
              <p>{t('Taquilla reservada durante')}</p>
              {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </div>
            <br></br>
            <div className="lockerTicket">
              <h5 className="lockTitle">
                <span className="text-vw-light">{t('PARQUE')}: <span className="text-vw-dark">{lockerCustomer}</span></span>
              </h5>
              <h5 className="lockTitle">
                <span className="text-vw-light">{t('ZONA')}: <span className="text-vw-dark">{zoneSelected}</span></span>
              </h5>
              <h5 className="lockTitle">
                <span className="text-vw-light">{t('ACCESIBLE')}: <span className="text-vw-dark">{selectedAccessibility ? t('SI') : t('NO')}</span></span>
              </h5>
              <h5 className="lockTitle">
                {qrMode === 0 ? (
                  <span className="text-vw-light">{t('RESERVA')}: <span className="text-vw-dark">{t('HASTA FINALIZAR EL DÍA')}</span></span>
                ) : (
                  <span className="text-vw-light">{t('RESERVA')}: <span className="text-vw-dark">{t('2 APERTURAS')}</span></span>
                )
                }
              </h5>
              <h5 className="lockTitle">
                <span className="text-vw-light">{t('TIPO DE TAQUILLA')}: <br></br><span className="text-vw-dark">{this.getLockerType(selectedLocker)}</span></span>
              </h5>
            </div>
            <div className="lockerTicket">
              <h6 className="lockTitle">
                <span className="text-vw-dark">{t('IVA')}: {iva}%</span>
              </h6>
              <h6 className="lockTitle">
                <span className="text-vw-dark">{t('IMPORTE BASE')}: {importeBase}€</span>
              </h6>
              <h6 className="lockTitle">
                <span className="text-vw-dark">{t('IMPORTE IVA')}: {importeIva}€</span>
              </h6>
              <hr className="bg-vw-dark" />
              <h3 className="lockTitle">
                <span className="text-vw-dark">{t('TOTAL')}: {total}€</span>
              </h3>
            </div>
            {this.redsysForm()}
          </div>
          <div>
            <Modal isOpen={isTimeUp}>
              <ModalHeader>
                {t('Tiempo de reserva agotado')}
              </ModalHeader>
              <ModalBody>
                {t('Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.')}
              </ModalBody>
              <ModalFooter>
                <Button color="warning" className="button-center"
                  onClick={this.handleRedirect}>
                  {t('Volver al inicio')}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  auth: true,
  redsys: state.redsys,
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  postRedsys: bindActionCreators(postRedsys, dispatch),
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PaymentView));