import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { history } from '../store';
import AppHeader from '../containers/AppHeader';
import { postPrebooking } from '../actions/qrs'
import { withNamespaces } from 'react-i18next';

class AccessibilityView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      accessibilityType: null,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  goTo(accessibilityType, e) {
    const { zoneSelected, selectedLocker, descriptionZone, qr, lockerPrice, qrType, qrMode } = this.props.location.state;

    const prebooking = {}

    prebooking.Qr = qr
    prebooking.LockerSelected = selectedLocker
    prebooking.Zone = zoneSelected
    prebooking.Accessibility = accessibilityType
    prebooking.qrMode = qrMode

    this.props.postPrebooking(prebooking)
      .then((answer) => {

        const lockerAssigned = answer.response.answerPrebooking.LockerAssigned
        const lockerPin = answer.response.answerPrebooking.LockerPin
        const lockerMatricula = answer.response.answerPrebooking.LockerMatricula
        const lockerCustomer = answer.response.answerPrebooking.LockerCustomer
        const lockerCustomerID = answer.response.answerPrebooking.LockerCustomerID

        this.props.push('/PhoneView', {
          selectedLocker: selectedLocker,
          zoneSelected: zoneSelected,
          selectedAccessibility: accessibilityType,
          descriptionZone: descriptionZone,
          lockerAssigned: lockerAssigned,
          lockerPrice: lockerPrice,
          lockerPin: lockerPin,
          lockerMatricula: lockerMatricula,
          lockerCustomer: lockerCustomer,
          lockerCustomerID: lockerCustomerID,
          qr: qr,
          qrType: qrType,
          qrMode: qrMode
        })

      })
      .catch((error) => {
        console.error(error);
      });

  }

  componentDidMount() {
    const { zoneSelected, selectedLocker } = this.props.location.state;

  }

  render() {
    const { t, i18n } = this.props;
    const yesLabel = t('SI');
    const noLabel = t('NO');

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br />
          <br />
          <div className="col-xs-12">
            <img src="/images/disabled.svg" alt="Locker Accessibility" className="lockerAccessibilityImg" />
          </div>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('¿NECESITAS UNA TAQUILLA ADAPTADA?')}</span>
          </h3>
          <h5 className="lockTitle">
            <span className="text-vw-dark">{t('Tienes la opción de seleccionar una taquilla de altura adaptada para tu confort.')}</span>
          </h5>
          <div className="row">
            <div className="col lockerAccessibilityButtonNo" onClick={(e) => this.goTo(false, e)}>
              <h1>{noLabel}</h1>
            </div>
            <div className="col lockerAccessibilityButtonYes" onClick={(e) => this.goTo(true, e)}>
              <h1>{yesLabel}</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: true,
});

const mapDispatchToProps = (dispatch) => ({
  push: bindActionCreators(push, dispatch),
  postPrebooking: bindActionCreators(postPrebooking, dispatch)
});

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(AccessibilityView));
