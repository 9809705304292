import { CALL_API } from '../middleware/api'

export const DISPONIBILITY_REQUEST = 'DISPONIBILITY_REQUEST'
export const DISPONIBILITY_SUCCESS = 'DISPONIBILITY_SUCCESS'
export const DISPONIBILITY_FAILURE = 'DISPONIBILITY_FAILURE'

export function fetchDisponibility(disponibility) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `qrsbaqueira/disponibility`,
      authenticated: true,
      body: JSON.stringify(disponibility),
      types: [DISPONIBILITY_REQUEST, DISPONIBILITY_SUCCESS, DISPONIBILITY_FAILURE]
    }
  }
}

export const SALE_REQUEST = 'SALE_REQUEST'
export const SALE_SUCCESS = 'SALE_SUCCESS'
export const SALE_FAILURE = 'SALE_FAILURE'

export function postSale(sale) {

  const { PrerentCode: id } = sale;
  
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `qrsbaqueira/sale/${id}`,
      authenticated: true,
      headers: {
        'Content-Type': 'application/json; charset=utf-8', // Explicitly specify UTF-8
      },
      body: JSON.stringify(sale),
      types: [SALE_REQUEST, SALE_SUCCESS, SALE_FAILURE]
    }
  }
}

export const AVAILABILITY_REQUEST = 'AVAILABILITY_REQUEST'
export const AVAILABILITY_SUCCESS = 'AVAILABILITY_SUCCESS'
export const AVAILABILITY_FAILURE = 'AVAILABILITY_FAILURE'

export function fetchAvailability(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `qrsbaqueira/availability/${id}`,
      authenticated: true,
      types: [AVAILABILITY_REQUEST, AVAILABILITY_SUCCESS, AVAILABILITY_FAILURE]
    }
  }
}

export const UPDATE_PRERENT_REQUEST = 'UPDATE_PRERENT_REQUEST'
export const UPDATE_PRERENT_SUCCESS = 'UPDATE_PRERENT_SUCCESS'
export const UPDATE_PRERENT_FAILURE = 'UPDATE_PRERENT_FAILURE'

export function putUpdatePrerental(id, body) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `qrsbaqueira/updateprerental/${id}`,
      authenticated: true,
      body: JSON.stringify(body),
      types: [UPDATE_PRERENT_REQUEST, UPDATE_PRERENT_SUCCESS, UPDATE_PRERENT_FAILURE]
    }
  }
}

export function putUpdateSale(id, body) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `qrsbaqueira/updatesale/${id}`,
      authenticated: true,
      body: JSON.stringify(body),
      types: [UPDATE_PRERENT_REQUEST, UPDATE_PRERENT_SUCCESS, UPDATE_PRERENT_FAILURE]
    }
  }
}