import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next";
import { postQrsale, postBooking } from '../actions/qrs'
import StepBar from '../components/StepBar'

class PhoneView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      phoneNumber: '',
      mail: '',
      parque: 'AQUALANDIA',
      zona: '1',
      accesible: 'NO',
      reserva: 'TODO EL DIA',
      confirmReservation: false,
      newsletterAcceptance: false,
      lockerSelected: null,
      lockerPrice: null,
      descriptionZone: null,
      selectedAccessibility: null,
      zoneSelected: null,
      selectedLocker: null,
      lockerAssigned: null,
      lockerPrice: null,
      paymentId: null,
      lockerPin: null,
      lockerMatricula: null,
      lockerCustomer: null,
      qrType: "pay",
      qrMode: 0
    }

    this.toggle = this.toggle.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handlePhoneNumberChange(event) {
    const phoneNumber = event.target.value;
    this.setState({ phoneNumber });
  }

  handleMailChange(event) {
    const mail = event.target.value;
    const isValidEmail = this.validateEmail(mail);
    this.setState({ mail, isValidEmail });
  }

  validateEmail(email) {
    // A simple email validation pattern using regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  goTo(e) {
    const {
      zoneSelected,
      descriptionZone,
      selectedAccessibility,
      selectedLocker,
      lockerPrice,
      lockerAssigned,
      lockerPin,
      lockerMatricula,
      lockerCustomer,
      lockerCustomerID,
      qr,
      qrType,
      qrMode
    } = this.props.location.state;

    if (!this.isContinueDisabled()) {
      this.props.push('/PaymentView', {
        zoneSelected: zoneSelected,
        descriptionZone: descriptionZone,
        selectedAccessibility: selectedAccessibility,
        selectedLocker: selectedLocker,
        lockerPrice: lockerPrice,
        lockerAssigned: lockerAssigned,
        lockerPin: lockerPin,
        lockerMatricula: lockerMatricula,
        lockerCustomer: lockerCustomer,
        lockerCustomerID: lockerCustomerID,
        qr: qr,
        qrType: qrType,
        qrMode: qrMode,
        mail: this.state.mail,
        phoneNumber: this.state.phoneNumber,
        newsletterAcceptance: this.state.newsletterAcceptance,
        confirmReservation: this.state.confirmReservation
      })
    }
  }

  componentDidMount() {

    let autorizeBooking = true;

    const navigationEntries = window.performance.getEntriesByType('navigation');
    if ((navigationEntries.length > 0) && (navigationEntries[0].type === 'reload')) {
      console.log("Page was reloaded");
      autorizeBooking = false;
    }

    if (autorizeBooking) {

    }
  }

  isContinueDisabled() {

    const disabled = ((this.state.phoneNumber.length < 6) || !this.state.isValidEmail || !this.state.confirmReservation)

    return disabled;
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBar step={3} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('FACILITANOS TUS DATOS DE RESPALDO')}</span>
          </h3>
          <div className="lockTitle">
            <span className="text-vw-dark">{t('Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.')}</span>
          </div>
          <div>
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('TELÉFONO*')}</span>
            </h3>
            <input className="phoneInput"
              name="lockerPhone"
              type="tel"
              pattern="[0-9]*"
              value={this.state.phoneNumber}
              onChange={this.handlePhoneNumberChange}
            />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('EMAIL*')}</span>
            </h3>
            <input className="phoneInput"
              name="lockerEmail"
              type="text"
              value={this.state.mail}
              onChange={this.handleMailChange}
              autoCapitalize='none'
            />
            <br></br>
            <div className='divCheckbox'>
              <input
                type="checkbox"
                id="confirmReservation"
                name="confirmReservation"
                checked={this.state.confirmReservation}
                onChange={() => this.setState({ confirmReservation: !this.state.confirmReservation })}
              />
              <div className="slider"></div>
              <label htmlFor="confirmReservation" className='textcheckbox slide-checkbox'>
                {t('Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.')}
              </label>
            </div>
            <div className='divCheckbox'>
              <input
                type="checkbox"
                id="newsletterAcceptance"
                name="newsletterAcceptance"
                checked={this.state.newsletterAcceptance}
                onChange={() => this.setState({ newsletterAcceptance: !this.state.newsletterAcceptance })}
              />
              <div className="slider"></div>
              <label htmlFor="newsletterAcceptance" className='textcheckbox slide-checkbox'>
                {t('Acepto suscribirme al boletín informativo de VACWAY para recibir contenido y notificaciones comerciales.')}

              </label>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div
              className={`col lockerPayButton ${this.isContinueDisabled() ? "disabled" : ""}`}
              onClick={(e) => this.goTo(1, e)}
            >
              <h1>{t("CONTINUAR")}</h1>
            </div>
          </div>
          <br></br>
          <div className="lockTitle">
            <span className="text-vw-dark">{t('*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.')}</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PhoneView));
