import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppBaqueiraHeader from '../containers/AppBaqueiraHeader'
import { withNamespaces } from "react-i18next"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { fetchAvailability, postCancelPrebooking } from '../actions/qrs'
import StepBarBaqueira from '../components/StepBarBaqueira'

class ZoneSelectorBaqueiraView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      isMapFullscreen: false,
      zones: null,
      description: null,
      customerName: null,
      forwardScreen: false,
      machineSleeping: false
    }

    this.toggle = this.toggle.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleMapFullscreen() {
    this.setState({
      isMapFullscreen: !this.state.isMapFullscreen
    })
  }

  goTo(zoneNumber, descriptionZone, e) {
    const { id } = this.props.match.params;
    const { qrMode } = this.props.location.state;

    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);
        history.push('/lockerSelectorBaqueiraView', { zoneSelected: zoneNumber, descriptionZone: descriptionZone, qr: id, customerName: this.state.customerName, qrMode: qrMode });
      }
    );
  }

  componentDidMount() {

    const { id } = this.props.match.params;
    const searchParams = new URLSearchParams(this.props.location.search);
    //const lockerAssigned = searchParams.get('lockerAssigned');

    let autorizeCancelPrebooking = true;

    const navigationEntries = window.performance.getEntriesByType('navigation');
    if ((navigationEntries.length > 0) && (navigationEntries[0].type === 'reload')) {
      console.log("Page was reloaded");
      autorizeCancelPrebooking = false;
    }

    // Get current Spain time
    const now = new Date();
    const spainTime = new Intl.DateTimeFormat('en-US', {
      timeZone: 'Europe/Madrid',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    }).formatToParts(now);

    // Extract the hour part
    const hour = parseInt(spainTime.find(part => part.type === 'hour').value, 10);

    // Check if the time is between 22:00 and 01:00
    if ((hour == 23) || (hour == 0)) {
      this.setState({
        machineSleeping: true, // Add a flag for sleeping state
      });
      return;
    }

    this.props.fetchAvailability(id)
      .then((answer) => {

        let answerZones = answer.response.answerZones

        if (answerZones.multipleZones) {
          this.setState({ zones: answerZones.multipleZones });
          this.setState({ description: answerZones.descriptionZones });
        } else if (answerZones.singleZone) {
          this.goTo(answerZones.singleZone, answerZones.descriptionZones[(answerZones.singleZone) - 1], 0)
        }

        this.setState({ customerName: answerZones.customerName });
      })
      .catch((error) => {
        console.error(error);
      });

  }

  renderSleepingMessage() {
    const { t } = this.props;

    return (
      <div>
        <AppBaqueiraHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBarBaqueira step={1} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('BIENVENIDO A')}</span>
            <br></br>
            <span className="text-vw-light-baqueira">BAQUEIRA BERET</span>
          </h3>
          <br></br>
          <div className="sleeping-message lockTitle w-75 m-auto">
            <h3 className="text-warning">{t('Fuera de horario')}</h3>
            <p>{t('Lo sentimos, pero la venta no está disponible entre las 23:00h y la 01:00h, vuelva fuera de este horario.')}</p>
            <img src="/images/sleeping_machine.webp" alt="Sleeping Machine" className="sleeping-image w-75" />
          </div>
        </div>
      </div>
    );
  }

  getMapPicture(type) {
    switch (type) {
      case 0:
        return '/images/maps/mapaportaventura.svg'
      default:
        return '/images/maps/mapaportaventura.svg'
    }
  }

  renderZones() {
    const { t, i18n } = this.props
    const { zones, description, customerName } = this.state;
    const zoneDivs = []

    if (!zones || !description) {
      return (<div class="spinner-baqueira"><img src="/images/logo_baqueira.png" alt="Logo" /></div>)
    } else {
      zones
        .sort((a, b) => a - b)
        .map((zone) => (

          zoneDivs.push(
            <div className="col-xs-12" key={zone}>
              <div className="lockButtonBaqueira" onClick={(e) => this.goTo(zone, description[zone - 1], e)}>
                <div className="row">
                  <div className="col col-4">
                    <img src="/images/Ping.svg" alt={"Ping " + zone} className="pingImage" />
                    <figcaption class='h5 numping'>{t(zone)}</figcaption>
                  </div>
                  <div className="col col-8 zoneDescription">
                    <h5>{t('ZONA ') + zone}</h5>
                    <p>{t(description[zone - 1])}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        ));

      return (<div>{zoneDivs}</div>)
    }
  }

  render() {
    const { t, i18n } = this.props
    const { zones, description, customerName, machineSleeping } = this.state;

    if (machineSleeping) {
      return this.renderSleepingMessage();
    }

    return (
      <div>
        <AppBaqueiraHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBarBaqueira step={1} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('BIENVENIDO A')}</span>
            <br></br>
            <span className="text-vw-light-baqueira">{customerName}</span>
          </h3>
          <h3 className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('SELECCIONA LA ZONA DE TAQUILLAS')}</span>
          </h3>

          {this.renderZones()}

          {/* <div className="col-xs-12">
            <Button className="mapButton" onClick={() => this.toggleMapFullscreen()}>VER MAPA DE ZONAS</Button>
          </div> */}

          <div>
            <Modal isOpen={this.state.isMapFullscreen}
              toggle={this.toggleMapFullscreen}>
              <ModalHeader toggle={this.toggleMapFullscreen}>
                MAPA DEL PARQUE
              </ModalHeader>
              <ModalBody>
                <img src={this.getMapPicture(0)} alt="Mapa" className="fullscreenMapImage" />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => this.toggleMapFullscreen()}>
                  Cerrar
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true,
  availability: state.availability
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  fetchAvailability: bindActionCreators(fetchAvailability, dispatch),
  postCancelPrebooking: bindActionCreators(postCancelPrebooking, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(ZoneSelectorBaqueiraView));