import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppBaqueiraHeader from '../containers/AppBaqueiraHeader'
import { withNamespaces } from "react-i18next";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { postSale, putUpdateSale } from '../actions/qrsbaqueira'
import { postRedsysGetParams, fetchCustomer } from '../actions/qrs'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TicketBaqueiraView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      iva: 21,
      importeBase: null,
      importeIva: null,
      total: null,
      date: new Date().toLocaleString(),
      accessCode: '',
      forwardScreen: false,
      downloadingPDF: false,
      descriptionZone: null,
      zoneSelected: null,
      selectedLocker: null,
      lockerAssigned: null,
      lockerPrice: null,
      qrToken: null,
      lockerMatricula: null,
      lockerCustomer: null,
      qrType: null,
      qrMode: null,
      lockerInvoice: null,
      language: null,
      startDate: null,
      endDate: null,
      customerName: null,
      mail: null,
      phoneNumber: null,
      pinAssigned: [],
      Ds_MerchantCode: null,
      Ds_MerchantName: 'BAQUEIRA BERET',
      Ds_Url: 'https://vacway.com',
      Ds_AuthorisationCode: null,
      Ds_Date: null,
      Ds_Hour: null,
    }

    this.generatePDF = this.generatePDF.bind(this);
  }

  componentDidMount() {
    // Add the beforeunload event listener
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  
    let authorizeBooking = true;
  
    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      console.log("Page was reloaded");
      authorizeBooking = false;
    }
  
    if (authorizeBooking) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const lockerPrice = searchParams.get('lockerPrice');
      const zoneSelected = searchParams.get('zoneSelected');
      const descriptionZone = searchParams.get('descriptionZone');
      const selectedLocker = searchParams.get('selectedLocker');
      const lockerAssigned = searchParams.get('lockerAssigned');
      const prerentCode = searchParams.get('prerentCode');
  
      const qrToken = searchParams.get('qrToken');
      const lockerMatricula = searchParams.get('lockerMatricula');
      const lockerCustomer = searchParams.get('lockerCustomer');
      const qrType = searchParams.get('qrType');
      const language = searchParams.get('language');
      const qrMode = searchParams.get('qrMode');
  
      const startDate = searchParams.get('startDate');
      const endDate = searchParams.get('endDate');
      const customerName = decodeURIComponent(searchParams.get('customerName') || '');
      const mail = searchParams.get('mail');
      const phoneNumber = searchParams.get('phoneNumber');
  
      const paymentId = searchParams.get('paymentId');
  
      const dsSignatureVersion = searchParams.get('Ds_SignatureVersion');
      const dsMerchantParameters = searchParams.get('Ds_MerchantParameters');
      const dsSignature = searchParams.get('Ds_Signature');
  
      console.log("Customer Name:", customerName);
  
      this.props.i18n.changeLanguage(language);
  
      this.setState(
        {
          lockerPrice: lockerPrice || 0,
          descriptionZone: descriptionZone,
          zoneSelected: zoneSelected,
          selectedLocker: selectedLocker,
          lockerAssigned: lockerAssigned,
          qrToken: qrToken,
          lockerMatricula: lockerMatricula,
          lockerCustomer: lockerCustomer,
          qrType: qrType,
          qrMode: qrMode,
          lockerInvoice: prerentCode,
          language: language,
          startDate: startDate,
          endDate: endDate,
          customerName: customerName,
          mail: mail,
          phoneNumber: phoneNumber,
        },
        () => {
          const saleData = {
            PrerentCode: this.state.lockerInvoice,
            CustomerName: this.state.customerName,
            CustomerEmail: this.state.mail,
            CustomerPhone: this.state.phoneNumber,
            EmailLanguage: this.state.language === 'en' ? 'gb' : 'es',
            SendEmail: true,
            SaleQR: this.state.qrToken,
            auth_code: null,
            payment_id: paymentId,
            order_id: null,
            merchant_id: null,
            payment_date: null,
            payment_time: null,
          };
  
          const paramsRedsys = {
            Ds_SignatureVersion: dsSignatureVersion,
            Ds_MerchantParameters: dsMerchantParameters,
            Ds_Signature: dsSignature,
            parameterToFetch: 'all',
            secretKey: null,
          };
  
          // Post the sale data
          this.props.postSale(saleData)
            .then((answer) => {
              const today = new Date().toLocaleString();
              const pinAssigned = answer.response.PinAssigned || [];
  
              this.setState({
                date: today,
                pinAssigned: pinAssigned,
              });
  
              this.TotalComponent();
  
              // Fetch customer data
              return this.props.fetchCustomer('6669b7f41628a3b21f4ceaa0');
            })
            .then((customer) => {
              if (!customer || !customer.response || !customer.response.Redsys) {
                throw new Error("Invalid customer response or missing Redsys data");
              }
  
              paramsRedsys.secretKey = customer.response.Redsys.SECRETKEY;
  
              // Fetch Redsys parameters
              return this.props.postRedsysGetParams(paramsRedsys);
            })
            .then((answer) => {
              console.log("RedsysResponse:", answer);
  
              const saleUpdateDate = {
                auth_code: answer.response.decodedParams?.Ds_AuthorisationCode || 'N/A',
                order_id: answer.response.decodedParams?.Ds_Order || 'N/A',
                merchant_id: answer.response.decodedParams?.Ds_MerchantCode || 'N/A',
                payment_date: answer.response.decodedParams?.Ds_Date || new Date().toLocaleDateString(),
                payment_time: answer.response.decodedParams?.Ds_Hour || new Date().toLocaleTimeString(),
              };
  
              // Update the sale
              this.props.putUpdateSale(prerentCode, saleUpdateDate);
  
              this.setState({
                Ds_AuthorisationCode: saleUpdateDate.auth_code,
                Ds_MerchantCode: saleUpdateDate.merchant_id,
                Ds_Date: saleUpdateDate.payment_date,
                Ds_Hour: saleUpdateDate.payment_time,
              });
            })
            .catch((error) => {
              console.error("An error occurred:", error);
  
              // Ensure fallback or recovery logic
              this.TotalComponent();
            });
        }
      );
    }
  }
  
  componentWillUnmount() {
    // Remove the beforeunload event listener to prevent memory leaks
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    // Set the returnValue property of the event to show the alert message
    event.preventDefault();
  };

  getImageDataURL = (imageUrl) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };

      image.onerror = (error) => {
        reject(error);
      };

      image.src = imageUrl;
    });
  };

  TotalComponent() {
    const { iva, lockerPrice } = this.state;

    let importeBase = (lockerPrice / (1 + (iva / 100))).toFixed(2)
    let importeIva = (lockerPrice - importeBase).toFixed(2)
    let total = Number(lockerPrice).toFixed(2)

    this.setState({ importeBase: importeBase, importeIva: importeIva, total: total });

    return { total, importeBase, importeIva, iva };
  }

  getLockerType(type) {

    const { t, i18n } = this.props

    switch (type) {

      case "BNS":
        return t('BÁSICA')
      case "CNS":
        return t('BÁSICA CARGA')
      case "BWS":
        return t('BÁSICA XL')
      case "CWS":
        return t('BÁSICA CARGA XL')
      case "BNM":
        return t('GRANDE')
      case "CNM":
        return t('GRANDE CARGA')
      case "BWM":
        return t('GRANDE XL')
      case "CWM":
        return t('GRANDE CARGA XL')
      case "BNL":
        return t('GRANDE')
      case "CNL":
        return t('GRANDE CARGA')
      case "BWL":
        return t('GRANDE XL')
      case "CWL":
        return t('GRANDE CARGA XL')
      case "BNXL":
        return t('GRANDE')
      case "CNXL":
        return t('GRANDE CARGA')
      case "BWXL":
        return t('GRANDE XL')
      case "CWXL":
        return t('GRANDE CARGA XL')
      case "BN2XL":
        return t('GRANDE')
      case "CN2XL":
        return t('GRANDE CARGA')
      case "BW2XL":
        return t('GRANDE XL')
      case "CW2XL":
        return t('GRANDE CARGA XL')
      case "BN3XL":
        return t('2 EQUIPOS DE ESQUÍ')
      case "CN3XL":
        return t('3 EQUIPOS DE ESQUÍ')
      case "BW3XL":
        return t('4 EQUIPOS DE ESQUÍ')
      case "CW3XL":
        return t('SKI CARGA XL')
      default:
        return t('BÁSICA')
    }
  }

  render() {
    const { t, i18n } = this.props
    const { iva, importeBase, importeIva, total, date, accessCode, downloadingPDF, zoneSelected, selectedLocker, lockerCustomer, lockerAssigned, lockerInvoice, descriptionZone, lockerSelected, qrToken, startDate, endDate, pinAssigned,
      Ds_MerchantCode,
      Ds_MerchantName,
      Ds_Url,
      Ds_AuthorisationCode,
      Ds_Date,
      Ds_Hour
    } = this.state;

    if (!lockerAssigned) {
      return (<div class="spinner-baqueira"><img src="/images/logo_baqueira.png" alt="Logo" /></div>)
    }

    // Helper function to parse DD/MM/YYYY to a Date object
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split('/'); // Split the string into parts
      return new Date(`${year}-${month}-${day}`); // Create a Date object
    };

    // Helper function to format a Date object as YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    // Parse startDate and get today's date
    const today = formatDate(new Date());
    const normalizedStartDate = formatDate(parseDate(startDate));

    // Check if the startDate is the same as today's date
    const isStartDateToday = startDate && normalizedStartDate === today;

    console.log(`startDate: ${startDate} / ${today} / ${isStartDateToday}`);

    return (
      <div>
        <AppBaqueiraHeader />
        <div className="lockerContainer">
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('¡GRACIAS POR RESERVAR TU TAQUILLA!')}</span>
          </h3>
          <div className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('Sigue las instrucciones que encontrarás a continuación o en tu email.')}</span>
          </div>
          <br></br>
          <div className="lockerTicket-baqueira">
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('TICKET')}</span>
            </h3>
            <hr className="bg-vw-dark-baqueira" />
            {isStartDateToday ? (
              <>
                <h4 className="lockTitle">
                  <span className="text-vw-light-baqueira">{t('Nº TAQUILLA')} - {t('PIN')}</span>
                </h4>
                <h3 className="lockTitle flex justify-content-around">
                  {lockerAssigned
                    .split(",")
                    .map((matricula, index) => (
                      <span key={index} className="lockLabel">
                        Nº {matricula} - {pinAssigned[index]}
                      </span>
                    ))}
                </h3>
              </>
            ) : (
              <>
                <h4 className="lockTitle">
                  <span className="text-vw-light-baqueira">{t('Nº TAQUILLA')}</span>
                </h4>
                <h3 className="lockTitle flex justify-content-around">
                  {lockerAssigned
                    .split(",")
                    .map((matricula, index) => (
                      <span key={index} className="lockLabel">
                        {matricula}
                      </span>
                    ))}
                </h3>
                <h4 className="lockTitle">
                  <span className="text-vw-light-baqueira">{t('PIN')}</span>
                </h4>
                <h6 className="lockTitle">
                  <span className="text-vw-dark-baqueira">
                    {t('Disponible en tu email que se enviará el día de inicio de tu reserva')}
                  </span>
                </h6>
              </>
            )
            }
            <hr className="bg-vw-dark-baqueira" />
            <h6 className="lockTitle">
              <span className="text-vw-light-baqueira">{t('ZONA')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{zoneSelected} - {descriptionZone}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light-baqueira">{t('TIPO')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{this.getLockerType(selectedLocker)}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light-baqueira">{t('CANTIDAD')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{lockerAssigned.split(",").length}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light-baqueira">{t('RESERVA')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{startDate} - {endDate}</span>
            </h6>
            <hr className="bg-vw-dark-baqueira" />
            <h6 className="lockTitle">
              <span className="text-vw-light-baqueira">{t('FACTURA')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{lockerInvoice}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light-baqueira">{t('FECHA COMPRA')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{date}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('IVA')}: {iva}%</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('IMPORTE BASE')}: {importeBase}€</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('IMPORTE IVA')}: {importeIva}€</span>
            </h6>
            <hr className="bg-vw-dark-baqueira" />
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('TOTAL')}: {total}€</span>
            </h3>
            <hr className="bg-vw-dark-baqueira" />
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('DATOS OPERACIÓN')}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('FUC: ')}{Ds_MerchantCode}</span><br></br>
              <span className="text-vw-dark-baqueira">{t('COMERCIO: ')}{Ds_MerchantName}</span><br></br>
              <span className="text-vw-dark-baqueira">{t('URL: ')}{Ds_Url}</span><br></br>
              <span className="text-vw-dark-baqueira">{t('AUTH: ')}{Ds_AuthorisationCode}</span><br></br>
              <span className="text-vw-dark-baqueira">{t('FECHA: ')}{Ds_Hour}/{Ds_Date}</span><br></br>
            </h6>
          </div>
          <div className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('*Si deseas obtener el Pin en formato físico para llevarlo durante tu estancia en el parque contacta con los responsables de la zona de taquillas y enséñales tu email de reserva.')}</span>
          </div>
          <br></br>
          {downloadingPDF ? (
            <div class="spinner-baqueira"><img src="/images/logo_baqueira.png" alt="Logo" /></div>
          ) : (
            <div className="row mt-3 mb-5">
              <div className="col lockerDownloadButtonBaqueira" onClick={this.generatePDF}>
                <span>{t('DESCARGAR TICKET')}</span>
              </div>
            </div>
          )
          }
          <div className="lockerInstructions">
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('INSTRUCCIONES')}</span>
            </h3>
            <hr className="bg-vw-dark-baqueira" />
            <div className="row">
              <div className="col flex align-items-center">
                <img src="/images/lockerBasicClosedStep1.svg" alt="Locker Charger" className="lockerTicketImage" />
              </div>
              <div className="col">
                <h5>
                  <span className="text-vw-light-baqueira">{t('PASO 1')}</span>
                </h5>
                <h5>
                  <span className="text-vw-dark-baqueira">{t('LOCALIZA TU TAQUILLA')}</span>
                </h5>
              </div>
            </div>
            <hr className="bg-vw-dark-baqueira" />
            <div className="row">
              <div className="col">
                <h5>
                  <span className="text-vw-light-baqueira">{t('PASO 2')}</span>
                </h5>
                <h5>
                  <span className="text-vw-dark-baqueira">{t('TOCA EL TECLADO')}</span>
                </h5>
              </div>
              <div className="col flex align-items-center">
                <img src="/images/lockerBasicClosedStep2.svg" alt="Locker Charger" className="lockerTicketImage" />
              </div>
            </div>
            <hr className="bg-vw-dark-baqueira" />
            <div className="row">
              <div className="col flex align-items-center">
                <img src="/images/lockerBasicClosedStep3.svg" alt="Locker Charger" className="lockerTicketImage" />
              </div>
              <div className="col">
                <h5>
                  <span className="text-vw-light-baqueira">{t('PASO 3')}</span>
                </h5>
                <h5>
                  <span className="text-vw-dark-baqueira">{t('INTRODUCE TU PIN')}</span>
                </h5>
              </div>
            </div>
            <hr className="bg-vw-dark-baqueira" />
            <div className="row">
              <div className="col">
                <h5>
                  <span className="text-vw-light-baqueira">{t('PASO 4')}</span>
                </h5>
                <h5>
                  <span className="text-vw-dark-baqueira">{t('ABRE LAS VECES QUE QUIERAS')}</span>
                </h5>
              </div>
              <div className="col flex align-items-center">
                <img src="/images/lockerBasicOpenedStep4.svg" alt="Locker Charger" className="lockerTicketImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  generatePDF = async () => {
    const { iva, importeBase, importeIva, total, date, accessCode, zoneSelected, selectedLocker, lockerCustomer, lockerMatricula, lockerAssigned, lockerInvoice, descriptionZone, lockerSelected, startDate, endDate } = this.state;

    const qrMode = 0; //HARDCODED

    const { t, i18n } = this.props

    this.setState({ downloadingPDF: true });

    const logocolorDataURL = await this.getImageDataURL('/images/logocolor_baqueira.png');

    const step1DataURL = await this.getImageDataURL('/images/lockerBasicClosedStep1.png');
    const step2DataURL = await this.getImageDataURL('/images/lockerBasicClosedStep2.png');
    const step3DataURL = await this.getImageDataURL('/images/lockerBasicClosedStep3.png');
    const step4DataURL = await this.getImageDataURL('/images/lockerBasicOpenedStep4.png');

    const step1OTODataURL = await this.getImageDataURL('/images/qr.png');
    const step2OTODataURL = await this.getImageDataURL('/images/lockerOTOStep2.png');
    const step3OTODataURL = await this.getImageDataURL('/images/lockerOpen.png');
    const step4OTODataURL = await this.getImageDataURL('/images/lockerBasicOpenedStep4.png');

    const docDefinition = {
      footer: {
        columns: [
          { text: `${t('Más información y garantía en www.vacway.com')}`, style: 'documentFooterCenter' },
        ]
      },
      content: [
        {
          columns: [
            {
              image: logocolorDataURL,
              width: 150
            },

            [
              {
                text: `${t('TICKET')}`,
                style: 'invoiceTitle',
                width: '*'
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: `${t('Reserva')}`,
                        style: 'invoiceSubTitle',
                        width: '*'

                      },
                      {
                        text: `${lockerInvoice}`,
                        style: 'invoiceSubValue',
                        width: 150

                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        text: `${t('Fecha y Hora')}`,
                        style: 'invoiceSubTitle',
                        width: '*'
                      },
                      {
                        text: new Date().toLocaleString(),
                        style: 'invoiceSubValue',
                        width: 150
                      }
                    ]
                  },
                ]
              }
            ],
          ],
        },
        '\n\n\n\n',
        {
          columns: [
            {
              text: `${t('INFORMACIÓN DE TU LOCKER:')}`,
              style: 'invoiceBillingTitle',

            },
            {
              text: 'BAQUEIRA BERET S.A.',
              style: 'invoiceBillingTitle',

            },
          ]
        },
        {
          columns: [
            {
              text: `${t('Nº TAQUILLA')}:  ${lockerAssigned}\n ${t('ZONA')}: ${zoneSelected} - ${descriptionZone} \n ${t('TIPO')}: ${this.getLockerType(selectedLocker)}\n ${t('CANTIDAD')}: ${lockerAssigned.split(",").length} \n ${t('RESERVA')}: ${startDate} - ${endDate}`,
              style: 'invoiceBillingDetails'
            },
            {
              text: 'Afores SN \n 25598 Baqueira \n Lleida, España \n CIF: A25005620',
              style: 'invoiceBillingDetails'
            },
          ]
        },
        '\n\n',
        {
          table: {
            headerRows: 1,
            widths: ['*', 40, 'auto', 40, 'auto', 80],

            body: [
              [
                {
                  text: `${t('Producto')}`,
                  style: 'itemsHeader'
                },
                {
                  text: `${t('UNIDADES')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE BASE')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('TOTAL')}`,
                  style: ['itemsHeader', 'center']
                }
              ],
              [
                [
                  {
                    text: `${this.getLockerType(selectedLocker)}`,
                    style: 'itemNumber'
                  },

                ],
                {
                  text: `${lockerAssigned.split(",").length}`,
                  style: 'itemNumber'
                },
                {
                  text: `${importeBase}`,
                  style: 'itemNumber'
                },
                {
                  text: `${iva}%`,
                  style: 'itemNumber'
                },
                {
                  text: `${importeIva}`,
                  style: 'itemNumber'
                },
                {
                  text: `${total}`,
                  style: 'itemTotal'
                }
              ],
              // END Items
            ]
          },
          layout: 'lightHorizontalLines'
        },
        '\n\n\n\n',
        {
          text: `${t('INSTRUCCIONES DE USO:')}`,
          style: 'invoiceBillingTitle',
        },
        '\n\n',
        {
          columns: [
            {
              image: step1DataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('PASO 1')}\n${t('LOCALIZA TU TAQUILLA')}`,
            },
            {
              image: step2DataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('PASO 2')}\n${t('TOCA EL TECLADO')}`,
            },
          ],

        },
        '\n\n\n\n',
        {
          text: ``,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step3DataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('PASO 3')}\n${t('INTRODUCE TU PIN')}`,
            },
            {
              image: step4DataURL,
              height: 80,
              width: 120,
            },

            {
              text: `\n${t('PASO 4')}\n${t('ABRE LAS VECES QUE QUIERAS')}`,
            },
          ],

        },
      ],

      styles: {
        // Document Footer
        documentFooterLeft: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'left'
        },
        documentFooterCenter: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'center'
        },
        documentFooterRight: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'right'
        },
        invoiceTitle: {
          fontSize: 22,
          bold: true,
          alignment: 'right',
          margin: [0, 0, 0, 15]
        },
        instrucciones: {
          fontSize: 22,
          bold: true,
          alignment: 'left',
          margin: [50, 0, 0, 15]
        },
        invoiceSubTitle: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceSubValue: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceBillingTitle: {
          fontSize: 14,
          bold: true,
          alignment: 'left',
          margin: [0, 20, 0, 5],
        },
        invoiceBillingDetails: {
          alignment: 'left',
          bold: true

        },
        itemsHeader: {
          margin: [0, 5, 0, 5],
          bold: true
        },
        itemTitle: {
          bold: true,
        },
        itemNumber: {
          margin: [0, 5, 0, 5],
          alignment: 'center',
        },
        itemTotal: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },

        itemsFooterSubTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterSubValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        foto1: {
          alignment: 'left',
        },
        itemsFooterTotalTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterTotalValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        signaturePlaceholder: {
          margin: [0, 70, 0, 0],
        },
        signatureName: {
          bold: true,
          alignment: 'center',
        },
        signatureJobTitle: {
          italics: true,
          fontSize: 10,
          alignment: 'center',
        },
        notesTitle: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 3],
          alignment: 'center',
        },
        notesText: {
          fontSize: 10
        },
        center: {
          alignment: 'center',
        },
      },
      defaultStyle: {
        columnGap: 20,
      }

    };

    const docOTODefinition = {
      footer: {
        columns: [
          { text: `${t('Más información y garantía en www.vacway.com')}`, style: 'documentFooterCenter' },
        ]
      },
      content: [
        {
          columns: [
            {
              image: logocolorDataURL,
              width: 150
            },

            [
              {
                text: `${t('TICKET')}`,
                style: 'invoiceTitle',
                width: '*'
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: `${t('Factura Simplificada')}`,
                        style: 'invoiceSubTitle',
                        width: '*'

                      },
                      {
                        text: `${lockerInvoice}`,
                        style: 'invoiceSubValue',
                        width: 150

                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        text: `${t('Fecha y Hora')}`,
                        style: 'invoiceSubTitle',
                        width: '*'
                      },
                      {
                        text: new Date().toLocaleString(),
                        style: 'invoiceSubValue',
                        width: 150
                      }
                    ]
                  },
                ]
              }
            ],
          ],
        },
        {
          columns: [
            {
              text: `${t('INFORMACIÓN DE TU LOCKER:')}`,
              style: 'invoiceBillingTitle',

            },
            {
              text: 'VACWAY WATERPROOF S.L',
              style: 'invoiceBillingTitle',

            },
          ]
        },
        {
          columns: [
            {
              text: `${t('Nº TAQUILLA')}:  ${lockerMatricula}\n ${t('CÓDIGO DE ACCESO')}: ${accessCode}\n ${t('ZONA')}: ${zoneSelected} \n ${t('TIPO')}: ${this.getLockerType(lockerSelected)}\n ${t('ACCESIBLE')}:  \n ${t('UBICACIÓN')}: ${lockerCustomer}`,
              style: 'invoiceBillingDetails'
            },
            {
              text: 'Albert Einstein, 46 \n 08223 Terrassa \n Barcelona, España \n NIF: B66983032',
              style: 'invoiceBillingDetails'
            },
          ]
        },
        '\n\n',
        {
          table: {
            headerRows: 1,
            widths: ['*', 40, 'auto', 40, 'auto', 80],

            body: [
              [
                {
                  text: `${t('Producto')}`,
                  style: 'itemsHeader'
                },
                {
                  text: `${t('UNIDADES')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE BASE')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('TOTAL')}`,
                  style: ['itemsHeader', 'center']
                }
              ],
              [
                [
                  {
                    text: `${this.getLockerType(lockerSelected)}`,
                    style: 'itemTitle'
                  },

                ],
                {
                  text: '1',
                  style: 'itemNumber'
                },
                {
                  text: `${importeBase}`,
                  style: 'itemNumber'
                },
                {
                  text: `${iva}%`,
                  style: 'itemNumber'
                },
                {
                  text: `${importeIva}`,
                  style: 'itemNumber'
                },
                {
                  text: `${total}`,
                  style: 'itemTotal'
                }
              ],
              // END Items
            ]
          },
          layout: 'lightHorizontalLines'
        },
        {
          text: `${t('PASO 1')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step1OTODataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('APRIETA "ABRIR TAQUILLA" O ESCANEA EL QR')}`,
            },
          ],

        },
        {
          text: `${t('PASO 2')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step2OTODataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('ACCEDE CON TUS CREDENCIALES')}`,
            },
          ],

        },
        {
          text: `${t('PASO 3')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step3OTODataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('SELECCIONA ABRIR AHORA')}`,
            },
          ],

        },
        {
          text: `${t('PASO 4')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step4OTODataURL,
              height: 80,
              width: 120,
            },

            {
              text: `\n${t('DISPONES DE 2 APERTURAS')}`,
            },
          ],

        },
      ],

      styles: {
        // Document Footer
        documentFooterLeft: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'left'
        },
        documentFooterCenter: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'center'
        },
        documentFooterRight: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'right'
        },
        invoiceTitle: {
          fontSize: 22,
          bold: true,
          alignment: 'right',
          margin: [0, 0, 0, 15]
        },
        instrucciones: {
          fontSize: 22,
          bold: true,
          alignment: 'left',
          margin: [0, 0, 0, 15]
        },
        invoiceSubTitle: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceSubValue: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceBillingTitle: {
          fontSize: 14,
          bold: true,
          alignment: 'left',
          margin: [0, 20, 0, 5],
        },
        invoiceBillingDetails: {
          alignment: 'left',
          bold: true

        },
        itemsHeader: {
          margin: [0, 5, 0, 5],
          bold: true
        },
        itemTitle: {
          bold: true,
        },
        itemNumber: {
          margin: [0, 5, 0, 5],
          alignment: 'center',
        },
        itemTotal: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },

        itemsFooterSubTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterSubValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        foto1: {
          alignment: 'left',
        },
        itemsFooterTotalTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterTotalValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        signaturePlaceholder: {
          margin: [0, 70, 0, 0],
        },
        signatureName: {
          bold: true,
          alignment: 'center',
        },
        signatureJobTitle: {
          italics: true,
          fontSize: 10,
          alignment: 'center',
        },
        notesTitle: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 3],
          alignment: 'center',
        },
        notesText: {
          fontSize: 10
        },
        center: {
          alignment: 'center',
        },
      },
      defaultStyle: {
        columnGap: 20,
      }

    };

    pdfMake.createPdf(qrMode === 0 ? docDefinition : docOTODefinition).download(`VACWAYlockers-${lockerInvoice}.pdf`);
    this.setState({ downloadingPDF: false });
  }

}

const mapStateToProps = state => ({
  auth: true,
  salebaqueira: state.salebaqueira,
  booking: state.booking
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  postSale: bindActionCreators(postSale, dispatch),
  postRedsysGetParams: bindActionCreators(postRedsysGetParams, dispatch),
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
  putUpdateSale: bindActionCreators(putUpdateSale, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(TicketBaqueiraView));